import { AutoComplete, Form, Input } from 'antd';
import './CustomerForm.css';
import { ReactNode, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

export default function CustomerForm(props) {
    const [results, setResults] = useState<any>([]);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const { t } = useTranslation('common');
    const [form] = Form.useForm();
    const { workOrder } = props;

    const validate = () => {
        setTimeout(() => {
            form.validateFields().then((field) => {
                props.validateForm(true);
            }).catch(() => {
                props.validateForm(false);
            });
        });
    };

    useEffect(() => {
        validate();
    }, []);

    function setNestedValue(obj, path, value) {
        const keys = path.split('.');
        let current = obj;
        while (keys.length > 1) {
            const key = keys.shift();
            if (!current[key]) {
                current[key] = {};
            }
            current = current[key];
        }
        current[keys[0]] = value;
    }

    function handleChange(value) {
        const { id } = value.target;
        setNestedValue(workOrder, id, value.target.value);
        props.updateWorkOrder(workOrder);
        validate();
    }

    function handleAddressSearch(value) {
        retrieveOptions(value);
        workOrder.address = value;
        props.updateWorkOrder(workOrder);
        validate();
    }

    function handleValueSelect(value) {
        const result = results.find((res) => res.address.label === value).address;
        const address = result.street === undefined ? '' : ((result.houseNumber === undefined ? '' : `${result.houseNumber} `) + result.street);
        const zip = result.postalCode === undefined ? '' : result.postalCode;
        const city = result.city === undefined ? '' : result.city;
        form.setFieldValue('address', address);
        form.setFieldValue('zip', zip);
        form.setFieldValue('city', city);
        workOrder.city = city;
        workOrder.address = address;
        workOrder.zipcode = zip;
        props.updateWorkOrder(workOrder);
        validate();
    }

    async function retrieveOptions(address) {
        if (address.trim().length > 3) {
            const fetchedOptions = await fetch(`/service/autocomplete?address=${address}&country=FRA`)
                .then((response) => response.json())
                .catch((error) => console.error(error));
            setResults(fetchedOptions);
            const newOptions = fetchedOptions.status !== undefined ? [] : fetchedOptions.map((option) => ({
                value: option.address.label,
            }));
            setOptions(newOptions);
        } else {
            setOptions([]);
        }
    }

    function handleChangePhone(value) {
        props.setPhoneNumber(value.target.value);
        validate();
    }

    function handleChangeEmail(value) {
        props.setEmailAddress(value.target.value);
        validate();
    }

    // const {TextArea} = Input;

    const customRequiredMark = (label: ReactNode, { required }: { required: boolean }) => (
        <>
            {label}
            {required ? (
                <span className="form-required-label">
                    (
                    {t('form.required')}
                    )
                </span>
            ) : ''}
        </>
    );

    if (props.workOrder !== undefined) {
        return (
            <Form form={form} ref={props.formRef} layout="vertical" requiredMark={customRequiredMark}>
                {/* <Form.Item name="type" label={t("form.type")} initialValue={workOrder.type.name || ""}> */}
                {/*    <Input id="typeName" disabled></Input> */}
                {/* </Form.Item> */}
                {/* <Form.Item name="description" label={t("form.description")} initialValue={workOrder.description || ""}> */}
                {/*    <TextArea disabled autoSize></TextArea> */}
                {/* </Form.Item> */}
                <Form.Item
                    name="name"
                    label={t('form.name')}
                    required
                    rules={[{ required: true, message: t('form.error.name') },
                        {
                            validator: (_, value) => (!(value.includes(' ') && value.trim().length === 0)
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('form.error.name')))),
                        }]}
                    initialValue={workOrder.customer.name || ''}
                >
                    <Input id="customer.name" onChange={(event) => handleChange(event)} />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label={t('form.phone')}
                    required
                    rules={[{ required: true, message: t('form.error.phone') },
                        {
                            validator: (_, value) => (!(value.includes(' ') && value.trim().length === 0)
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('form.error.phone')))),
                        }]}
                    initialValue={props.newPhoneNumber || workOrder.customer.phone || ''}
                >
                    <Input
                        id="customer.phone"
                        onChange={(event) => {
                            handleChangePhone(event);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={t('form.email')}
                    rules={[{ type: 'email', message: t('form.error.email') }]}
                    initialValue={props.newEmailAddress || workOrder.customer.email || ''}
                >
                    <Input
                        id="customer.email"
                        onChange={(event) => {
                            handleChangeEmail(event);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={t('form.address')}
                    required
                    rules={[{ required: true, message: t('form.error.address') },
                        {
                            validator: (_, value) => (!(value.includes(' ') && value.trim().length === 0)
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('form.error.address')))),
                        }]}
                    initialValue={workOrder.address || ''}
                >
                    <AutoComplete
                        id="address"
                        options={options}
                        onSelect={handleValueSelect}
                        onSearch={debounce(handleAddressSearch, 300)}
                        onChange={(event: string) => handleChange({
                            target: {
                                id: 'address',
                                value: event,
                            },
                        })}
                    />
                </Form.Item>
                <Form.Item
                    name="zip"
                    label={t('form.zip')}
                    required
                    rules={[{ required: true, message: t('form.error.zip') },
                        {
                            validator: (_, value) => (!(value.includes(' ') && value.trim().length === 0)
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('form.error.zip')))),
                        }]}
                    initialValue={workOrder.zipcode || ''}
                >
                    <Input id="zipcode" onChange={(event) => handleChange(event)} />
                </Form.Item>
                <Form.Item
                    name="city"
                    label={t('form.city')}
                    required
                    rules={[{ required: true, message: t('form.error.city') },
                        {
                            validator: (_, value) => (!(value.includes(' ') && value.trim().length === 0)
                                ? Promise.resolve()
                                : Promise.reject(new Error(t('form.error.city')))),
                        }]}
                    initialValue={workOrder.city || ''}
                >
                    <Input id="city" onChange={(event) => handleChange(event)} />
                </Form.Item>
                {/* <Form.Item name="additionalInfo" label={t('form.additionalInfo')} initialValue={props.additionalInfo || ""}> */}
                {/*    <TextArea className="form-additional-info" onChange={props.newAdditionalInfo} rows={4} /> */}
                {/* </Form.Item> */}
            </Form>
        );
    }
    return (
        <Form form={form} layout="vertical">
            <Form.Item label={t('form.type')}>
                <Input disabled />
            </Form.Item>
            <Form.Item label={t('form.description')}>
                <Input disabled />
            </Form.Item>
            <Form.Item label={t('form.name')}>
                <Input />
            </Form.Item>
            <Form.Item label={t('form.phone')}>
                <Input />
            </Form.Item>
            <Form.Item label={t('form.email')}>
                <Input />
            </Form.Item>
            <Form.Item label={t('form.address')}>
                <Input />
            </Form.Item>
            <Form.Item label={t('form.zip')}>
                <Input />
            </Form.Item>
            <Form.Item label={t('form.city')}>
                <Input />
            </Form.Item>
            {/* <Form.Item label={t('form.additionalInfo')}> */}
            {/*    <TextArea rows={4} /> */}
            {/* </Form.Item> */}

        </Form>
    );
}
